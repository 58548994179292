/* PRELOADER */
var glblpreloader = document.getElementsByClassName('se-pre-con');

$(window).on('load', function () {
	preloadTransition('load');
});

$('a').click(function (e) {
	preloadTransition('click', $(this));
});

function preloadTransition(plevent, cobj) {
	var pageWLoader = [];
	var pageData = document.querySelectorAll('[data-loaderpage]');

	for (var i = 0; i < pageData.length; i++) {
		pageWLoader.push($(pageData[i]).attr('data-loaderpage'));
	}

	if (plevent == "load") {
		$(".se-pre-con").removeClass('se-pre-con--loading');
	} else {
		var cLoc = $(cobj).attr('href');
		for (var i = 0; i < pageWLoader.length; i++) {
			if (cLoc && pageWLoader[i] == cLoc) {
				var hrefhostname = extractHostname(cobj.attr('href'));
				if (window.location.hostname == hrefhostname) {
					$(".se-pre-con").addClass('se-pre-con--transition');
				}
			}
		}
	}
}

function extractHostname(url) {
	var hostname;
	//find & remove protocol (http, ftp, etc.) and get hostname

	if (url.indexOf("//") > -1) {
		hostname = url.split('/')[2];
	} else {
		hostname = url.split('/')[0];
	}

	//find & remove port number
	hostname = hostname.split(':')[0];
	//find & remove "?"
	hostname = hostname.split('?')[0];

	return hostname;
}

/* PRELOADER END */

jQuery(function ($) {

	// CUSTOM CHECKBOX PLUS AND MINUS SIGNS CHANGING
	var inputs = $('.input');
	var inp = inputs.find('input');

	inp.on('input', function () {
		var v = $(this).val();
		if (v.length > 5) {
			$(this).val('10:00');
		}
	}).on('focusout', function () {
		var input = $(this);
		var v = input.val();

		if (v.substring(0, 1) === '0' && v.substring(1, 2) === '0') {
			input.val(v.substring(1, v.length))
		}
	})

	inputs.find('span').on('click', function () {
		var inp = $(this).siblings('input');
		var current = inp.val();
		current = current.split(':');

		var hrs = current[0];
		var min = current[1];

		if ($(this).hasClass('minus')) {

			if (inp.val().length > 5 || inp.val().indexOf(':') === -1) {
				inp.val('10:00');
				return false;
			}

			if (min === '0') {
				if (hrs === '10') {
					inp.val('17:45');
				} else {
					hrs--;
					inp.val(hrs + ':45');
				}
			} else {
				min = min - 15;

				if (min.toString().length === 1) {
					min = '0' + min;
				}

				if (parseInt(min) < 0) {
					hrs--;
					min = 60 - (-min);
				}

				if (parseInt(hrs) < 10) {
					hrs = '17';
				}

				inp.val(hrs + ':' + min);
			}
		} else {

			if (inp.val().length > 5 || inp.val().indexOf(':') === -1) {
				inp.val('10:00');
				return false;
			}

			if (min === '45') {
				if (hrs === '17') {
					inp.val('10:00')
				} else {
					hrs++;
					inp.val(hrs + ':00');
				}
			} else {
				min = parseInt(min) + 15;

				if (min.toString().length === 1) {
					min = '0' + min;
				}

				if (parseInt(min) > 60) {
					hrs++;
					min = '0' + (min - 60);
				}

				if (parseInt(hrs) > 17) {
					hrs = '10';
				}

				inp.val(hrs + ':' + min);
			}
		}

	})


	//ADD CLASS TO SUBMENU NAVIGATION
	$('.porcelanosa-ul .parent-li ul').addClass('menu-3');


	// REMOVE BR FROM CONTACT FORM WYSIWYG EDITOR
	$('.wpcf7-form').find('br').remove();


	// HOMEPAGE SLIDER
	$('.homepage-slider').slick({
		slidesToShow: 3,
		arrows: false,
		responsive: [{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});


	// UNIVERSAL TEMPLATE QUOTES SLIDER
	$('.quoted-slider').slick({
		slidesToShow: 1,
		arrows: false,
		dots: true,
		autoplay: true
	});


	// UNIVERSAL TEMPLATE SLIDER WITH BIG IMAGE START
	$('.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.uni-slider'
	});

	$('.uni-slider').slick({
		slidesToShow: 4,
		arrows: false,
		slidesToScroll: 1,
		asNavFor: '.slider-for',
		focusOnSelect: true,
		responsive: [{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('a[data-slide]').click(function (e) {
		e.preventDefault();
		var slideno = $(this).data('slide');
		$('.uni-slider').slick('slickGoTo', slideno - 1);
	});
	// UNIVERSAL TEMPLATE SLIDER WITH BIG IMAGE END


	// ADD PREV AND NEXT ARROW ON BLOG AND NEWS TOP
	$('.prev-holder a').prepend('<span class="font-long-right-arrow gray-top-span gray-top-left-span"></span>');
	$('.next-holder a').append('<span class="font-long-right-arrow gray-top-span gray-top-right-span"></span>');


	// NAVIGATION SUBMENU ON CLICK SHOW SUBMENU
	$('.sub-menu-plus').append('<span class="font-plus font-minus"></span>');

	function toggleSidemenu() {
		if (!$(this).parent().hasClass('opened-sidemenu')) {
			$('.porcelanosa-side-ul').find('.sub-menu').hide(400);
			$('.porcelanosa-side-ul').find('.font-minus').addClass('font-plus');
			$(this).parent().addClass('opened-sidemenu');
			$(this).prev().show(400);
			$(this).removeClass('font-plus');
		} else {
			$(this).parent().removeClass('opened-sidemenu');
			$(this).prev().hide(400);
			$(this).addClass('font-plus');
		}
	}

	$('.sub-menu-plus span').on('click', toggleSidemenu);


	// NAVIGATION BURGER ONCLICK SIDE NAVIGATION SHOW
	$('.burger-container').on('click', function () {
		$('.porcelanosa-side-ul').toggle('slow');
		$('.porcelanosa-side-ul').css('display', 'flex');

		if ($('#myBtn').hasClass('change')) {
			$('body').addClass('stop-scroll');
		} else {
			$('body').removeClass('stop-scroll');
		}
	})


	// CLONE SOCIAL ICONS TO SIDE NAVIGATON BOTTOM
	var clone = $('.homepage-video-social-ul').clone(true);
	$(clone).removeClass('homepage-video-social-ul');
	$(clone).addClass('navigation-bottom-social');
	$('#mySidenav').append(clone);


	// FLIPBOOK CATALOG PAGE
	var catalogTemplate = $('body.page-template-template-catalogs');
	if ($(catalogTemplate).length > 0) {
		var oTurn = $("#flipbook").turn({
			page: 2,
			width: 100 + ('%'),
			height: 610,
			duration: 1200,
			autoCenter: true,
			next: true,
			zoom: 1,
			when: {
				start: function (event, pageObject, corner) {
					if (pageObject.next == 1)
						event.preventDefault();
				},
				turning: function (event, page, view) {
					if (page == 1)
						event.preventDefault();
				}
			}
		});

		$("#prev").click(function (e) {
			e.preventDefault();
			oTurn.turn("previous");
		});

		$("#next").click(function (e) {
			e.preventDefault();
			oTurn.turn("next");
		});
	}


	// ONCLICK CHANGE TOP MAIN CATALOG
	$('.other-catalogs-single').on('click', function () {

		// replacing href attr
		var currentPdf = $('.pdf-catalog-file');
		var replacePdf = $(this).find('.pdf-other-catalog-file').attr('href');
		if (replacePdf == '') {
			$(currentPdf).removeAttr('href');
			$(currentPdf).removeAttr('download');
		} else {
			$(currentPdf).attr('href', replacePdf);
			$(currentPdf).attr('download', 'true');
		}
		// replacing href attr end

		// replacing catalog headline
		var currentHeadline = $('.catalog-headline');
		var replaceHeadline = $(this).find('.other-catalog-headline').html();
		$(currentHeadline).text(replaceHeadline);
		// replacing catalog headline end

		var allElements = $('.page-wrapper');
		$(allElements).each(function () {
			var id = $(this).attr('page');
			if (parseInt(id) > 2) {
				$("#flipbook").turn("removePage", parseInt(id));
			}
		});

		var firstImg = $(this).find('.other-catalogs-img img:first'); // ?
		var imgF = $(firstImg).attr('src');
		var allImg = $(this).find('.catalog-hidden-images img');

		var element = $("<div />").html("<img src='" + imgF + "' />");
		$("#flipbook").turn("addPage", element, 3);

		var count = allImg.length;

		if (count > 1) {
			for (var i = 0; i < allImg.length; i++) {
				var x = i + 4;
				var srcImg = allImg[i].src;
				var element = $("<div />").html("<img src='" + srcImg + "' />");
				$("#flipbook").turn("addPage", element, x);
			}
		} else if (count == 1) {
			var element = $("<div />").html("<img src='" + allImg[0].src + "' />");
			$("#flipbook").turn("addPage", element, 4);
		} else {
			return false;
		}

		/*Scrill back to top*/
		$('html, body').animate({
			scrollTop: $('.catalog-holder').offset().top - 150
		}, 'slow');
	});


	//INPUT TYPE CHECKBOX ADD TOGGLE CLASS
	$('input[type="checkbox"]').click(function () {
		$(this).toggleClass('checked');
		if ($(this).hasClass('checked')) {
			$(this).parent().parent().parent().parent().find('.checkmark').addClass('clicked');
		} else {
			$(this).parent().parent().parent().parent().find('.checkmark').removeClass('clicked');
		}
	});


	// ADDING PLACEHOLER TO TEXTAREA COMMENT
	if ($('body.single-blog').length > 0) {
		$('#comment').attr('placeholder', 'Poruka');
	}


	// SHOW RIGHT SLIDE FORM ONCLICK
	$('.homepage-appointment').click(function () {
		$(this).next().toggle('slow');
	});


	// NEWSLETTER PLAIN ANIMATION
	$(".font-paper-plane").hover(
		function () {
			$(this).addClass('bounceOut');
		},
		function () {
			$(this).removeClass('bounceOut');
		}
	);


	// ANIMATED SECTION LINK ONCLICK SHOW RIGHT SIDE CONTACT FORM
	$('.a-setameeting').on('click', function (event) {
		event.preventDefault();
		$('.set-an-appointment').toggle('slow');
	});

	// SLIDE IMAGES FROM LEFT ANIMATION
	/* Scroll grey background */
	var animation_elements1 = $.find('.animation-element1');
	var web_window = $(window);

	function check_if_in_view1() {

		var window_height = web_window.height();
		var window_top_position = web_window.scrollTop();
		var window_bottom_position = (window_top_position + window_height);

		$.each(animation_elements1, function () {

			var element = $(this);
			var element_height = $(element).outerHeight();
			var element_top_position = $(element).offset().top;
			var element_bottom_position = (element_top_position + element_height);

			if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
				element.addClass('in-view1');
			}
			// else {element.removeClass('in-view1');} //do again on scroll up
		});
	}

	/*  Animatable  */
	(function ($, win) {
		$.fn.inViewport = function (cb) {
			return this.each(function (i, el) {
				function visPx() {
					var elH = $(el).outerHeight(),
						offset = 250,
						H = $(win).height(),
						r = el.getBoundingClientRect(),
						t = r.top + offset,
						b = r.bottom - offset;
					return cb.call(el, Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H)));
				}
				visPx();
				$(win).on("resize scroll", visPx);
			});
		};
	}(jQuery, window));

	// calling function if object is in viewport
	$(".animatable").inViewport(function (px) {
		if (px) $(this).addClass('start-animation');
	});
	/*  Animatable end */

	/* on or scroll, detect elements in view */
	$(window).on('scroll resize', function () {
		check_if_in_view1()
	})
	/* Scroll image */
	var animation_elements = $.find('.animation-element');
	var web_window = $(window);

	function check_if_in_view() {

		var window_height = web_window.height();
		var window_top_position = web_window.scrollTop();
		var window_bottom_position = (window_top_position + window_height);

		$.each(animation_elements, function () {

			var element = $(this);
			var element_height = $(element).outerHeight();
			var element_top_position = $(element).offset().top;
			var element_bottom_position = (element_top_position + element_height);

			if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
				element.addClass('in-view');
			}
		});
	}

	/* on or scroll, detect elements in view */
	$(window).on('scroll resize', function () {
		check_if_in_view()
	})
	/* trigger our scroll event on initial load */
	$(window).trigger('scroll');
	/* SLIDE IMG FROM LEFT SCRIPT END */


	/* PARRALAX EFFECT ON SCROLL */
	var windowWhidth = window.innerWidth;

	function new_parallax(selector, speed, offset) {
		if (windowWhidth > 1580) {
			if (selector.length !== 0) {
				var sectionOffset = selector.offset().top;
				var scrolled = $(window).scrollTop() - sectionOffset;
				var sectionPosition = (scrolled * speed + offset) + 'px';
				selector.attr('style', 'top:' + sectionPosition);
			}
		}
	}
	$(window).scroll(function () {
		$('.homepage-img-wrapper, .modern-kitchens-img-holder, .about-usbottom-inner, .modern-kitchens-img-holder').each(function () {
			new_parallax($(this), .1, 30);
		});
	});

});


/*--------------------------------------------------------------
# Contact map
--------------------------------------------------------------*/


(function ($) {

	/*
	 *  new_map
	 *
	 *  This function will render a Google Map onto the selected jQuery element
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	4.3.0
	 *
	 *  @param	$el (jQuery element)
	 *  @return	n/a
	 */

	function new_map($el) {

		// var
		var $markers = $el.find('.marker');


		// vars
		var args = {
			zoom: 17,
			center: new google.maps.LatLng(0, 0),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: [{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [{
							"color": "#e9e9e9"
						},
						{
							"lightness": 17
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [{
							"color": "#f5f5f5"
						},
						{
							"lightness": 20
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.fill",
					"stylers": [{
							"color": "#ffffff"
						},
						{
							"lightness": 17
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.stroke",
					"stylers": [{
							"color": "#ffffff"
						},
						{
							"lightness": 29
						},
						{
							"weight": 0.2
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [{
							"color": "#ffffff"
						},
						{
							"lightness": 18
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "geometry",
					"stylers": [{
							"color": "#ffffff"
						},
						{
							"lightness": 16
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [{
							"color": "#f5f5f5"
						},
						{
							"lightness": 21
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [{
							"color": "#dedede"
						},
						{
							"lightness": 21
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [{
							"visibility": "on"
						},
						{
							"color": "#ffffff"
						},
						{
							"lightness": 16
						}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [{
							"saturation": 36
						},
						{
							"color": "#333333"
						},
						{
							"lightness": 40
						}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [{
						"visibility": "off"
					}]
				},
				{
					"featureType": "transit",
					"elementType": "geometry",
					"stylers": [{
							"color": "#f2f2f2"
						},
						{
							"lightness": 19
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.fill",
					"stylers": [{
							"color": "#fefefe"
						},
						{
							"lightness": 20
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.stroke",
					"stylers": [{
							"color": "#fefefe"
						},
						{
							"lightness": 17
						},
						{
							"weight": 1.2
						}
					]
				}
			]
		};


		// create map
		var map = new google.maps.Map($el[0], args);


		// add a markers reference
		map.markers = [];


		// add markers
		$markers.each(function () {

			add_marker($(this), map);

		});


		// center map
		center_map(map);


		// return
		return map;

	}

	/*
	 *  add_marker
	 *
	 *  This function will add a marker to the selected Google Map
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	4.3.0
	 *
	 *  @param	$marker (jQuery element)
	 *  @param	map (Google Map object)
	 *  @return	n/a
	 */

	function add_marker($marker, map) {

		// var
		var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

		// create marker
		var marker = new google.maps.Marker({
			position: latlng,
			map: map,
			icon: '/porcelanosa/wp-content/uploads/2018/05/marker.png'
		});

		// add to array
		map.markers.push(marker);

		// if marker contains HTML, add it to an infoWindow
		if ($marker.html()) {
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content: $marker.html()
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function () {

				infowindow.open(map, marker);

			});
		}

	}

	/*
	 *  center_map
	 *
	 *  This function will center the map, showing all markers attached to this map
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	4.3.0
	 *
	 *  @param	map (Google Map object)
	 *  @return	n/a
	 */

	function center_map(map) {

		// vars
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each(map.markers, function (i, marker) {

			var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

			bounds.extend(latlng);

		});

		// only 1 marker?
		if (map.markers.length == 1) {
			// set center of map
			map.setCenter(bounds.getCenter());
			map.setZoom(17);
		} else {
			// fit to bounds
			map.fitBounds(bounds);
		}

	}

	/*
	 *  document ready
	 *
	 *  This function will render each map when the document is ready (page has loaded)
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	5.0.0
	 *
	 *  @param	n/a
	 *  @return	n/a
	 */
	// global var
	var map = null;

	$(document).ready(function () {

		$('.acf-map').each(function () {

			// create map
			map = new_map($(this));

		});

	});

})(jQuery);


/* ONSCROLL FUNCTIONS */
$(window).scroll(function (e) {
	// Onscroll navigation fixed to top
	var $el = $('.site-header');
	var scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
	if (scrollPos > 0) {
		$el.addClass('site-header--sticky');
		$('.font-porcelanosa-logo').addClass('font-porcelanosa-logo--sticky');
		$('#ajaxsearchliteres1').addClass('fixed');
		$('.porcelanosa-ul .sub-menu').addClass('sub-menu--sticky'); 
	} else {
		$el.removeClass('site-header--sticky');
		$('.font-porcelanosa-logo').removeClass('font-porcelanosa-logo--sticky');
		$('#ajaxsearchliteres1').removeClass('fixed');
		$('.porcelanosa-ul .sub-menu').removeClass('sub-menu--sticky'); 
	}

	// Onscroll top fadeout
	$(".top").css("opacity", 1 - $(window).scrollTop() / 800);
	$(".top1").css("opacity", 1 - $(window).scrollTop() / 300);

});

adjustBodyPadding();

$(window).resize(function(){
	adjustBodyPadding();
});

function adjustBodyPadding() {
	
	var headerHeight = $('.site-header').outerHeight(false);
	var bodypadding = $('body').css('padding-top').slice(0, -2);
	
	if (bodypadding != headerHeight) {
		$('body').css('padding-top', headerHeight + 'px');
	}
}